import VisitorBase from "./VisitorBase";
const ns = "s";

class SocketVisitor extends VisitorBase {
    constructor(state) {
        super(state, ns)

        if (!this.state[ns])
            this.state[ns] = {};

        this.socketInfo = this.state[ns];
    }

    static fromProps(props) {
        return VisitorBase.fromProps(props, ns, (state) => new SocketVisitor(state));
    }

    setSocket(socket) {
        this.socketInfo.socket = socket;
    }

    getSocket() {
        return this.socketInfo.socket;
    }

    setLastTimeStamp(ts) {
        this.socketInfo.ts = ts;
    }

    getLastTimestamp() {
        return this.socketInfo.ts;
    }
}

export default SocketVisitor;