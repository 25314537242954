const stateVar = "state#1";

export const _loadState = (storage) => {
    try {
        const serializedState = storage.getItem(stateVar);

        if (serializedState === null) {
            return undefined;
        }

        const { pluginReducer, uiReducer } = JSON.parse(serializedState);
        return { pluginReducer, uiReducer }
    } catch (error) {
        return {}; // Ignore read errors
    }
};

export const _saveState = (storage, state) => {
    try {
        const {pluginReducer, uiReducer} = state;
        const serializedState = JSON.stringify({pluginReducer, uiReducer});
        storage.setItem(stateVar, serializedState);
    } catch (error) {
        // Ignore write errors.
        console.log(error);
    }
};
