import _ from "underscore";

const __emailRegexp = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";
const __phoneRegexp = "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$";

class JoinFormAdapter {
	constructor(payload) {
		this.payload = payload;

		if (_.isString(this.payload))
			this.payload = JSON.parse(this.payload);
	}

	get jsonSchema() {
		const items = this.payload;
		if (items) {
			const required = items.reduce((r, v) => {
				if (v.required)
					r.push(v.valueName);
				return r;
			}, []);

			const rValue = {
				type: "object",
				required: required,
				properties: {}
			};

			items.forEach(i => {
				const properties = {
					type: "string",
					title: i.displayName,
				}
				if (i.valueType === "email") {
					properties.format = "email";
					properties.pattern = __emailRegexp;
				} else if (i.valueType === "phone") {
					properties.pattern = __phoneRegexp;
				}
				rValue.properties[i.valueName] = properties;
			})
			return rValue;
		}
		return  {};
	}

	get uiSchema() {
		const rValue = {};
		const items = this.payload;

		if (items) {
			items.forEach(i => {
				switch (i.valueType.toLowerCase()) {
					case "phone": {
						rValue[i.valueName] = {
							"ui:options": {
								"inputType": "tel"
							}
						}
						break;
					}
					default: {
						// nothing
					}
				}
			});
		}

		return rValue;
	}

	get formData() {
		return {};
	}
}

export default JoinFormAdapter;
