import React from "react";
import useStyles from "../../useStyles";
import _ from "underscore";
import PollFormAdapter from "../../../model/adapter/PollFormAdapter";
import Layout from "../../Layout";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Form from "@rjsf/material-ui";

function PollForm(props) {
    const classes = useStyles();
    const {onFormSubmit, onFormDataChange, pollForm, payload} = props;

    const packet = _.isString(payload) ? JSON.parse(payload) : payload;
    const pollFormAdapter = new PollFormAdapter(packet);

    return (
        <Layout footerCopyright={true}>
            {
                (packet.message && _.isString(packet.message) && packet.message.length > 0)
                    ? (<Grid container>
                            <Grid item xs>
                                <Paper className={classes.message}>
                                    <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item >
                                            <Avatar>!</Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                            <span dangerouslySetInnerHTML={{__html: packet.message}} />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>)
                    : null}

            <Paper className={classes.form}>
                <Form schema={pollFormAdapter.jsonSchema}
                      uiSchema={pollFormAdapter.uiSchema}
                      onChange={onFormDataChange}
                      onSubmit={onFormSubmit}
                      formData={pollForm}
                      showErrorList={false}
                />
            </Paper>
        </Layout>
    );
}

export default PollForm;