import { connect } from 'react-redux';
import StatusView from "../component/StatusView";
import {briefingRestart} from "../action/briefing";

function mapStateToProp(state, ownProps) {
    return {
    };
}

function mapDispatchToProps(dispatch){
    return {
        onRestart: () => dispatch(briefingRestart())
    };
}

export default connect(mapStateToProp, mapDispatchToProps)(StatusView);

