import {MESSAGE_WHAT_I_MISSED} from "../../constant";

import SocketVisitor from "../../model/visitor/SocketVisitor";
import BriefingVisitor from "../../model/visitor/BriefingVisitor";

import _ from "underscore";

export const fetchWim = (roomInfo) => (dispatch, getState) => {
    const state = getState();
    const socketVisitor = new SocketVisitor(state.transportReducer);
    const socket = socketVisitor.getSocket();

    const briefingVisitor = new BriefingVisitor(state.pluginReducer);
    const { briefingId, clientId } = briefingVisitor.getContext();

    const data = _.isString(roomInfo) ? JSON.parse(roomInfo) : roomInfo;

    const payload = {
        timestamp: data.conferenceTimestamp,
        conferenceId: briefingId,
        sender: clientId
    }

    socket.emit(MESSAGE_WHAT_I_MISSED, payload);
}