import _ from "underscore";

class PollFormAdapter {
    constructor(payload) {
        this.payload = payload;
        if (_.isString(this.payload))
            this.payload = JSON.parse(this.payload);
    }

    get jsonSchema() {
        if (this.payload?.items) {
            const rValue = {
                type: "object",
                properties: {}
            };

            this.payload.items.forEach(item => {
                if (item.items.uiType === "checkbox") {
                    rValue.properties[item.valueName] = {
                        title: "",
                        type: "array",
                        items: {
                            type: item.items.type,
                            enum: item.items.enum
                        },
                        "uniqueItems": true
                    }
                } else {
                    rValue.properties[item.valueName] = {
                        title: "",
                        type: "string",
                        enum: item.items.enum
                    }
                }
            })

            return rValue;
        }

        return {};
    }
    get uiSchema() {
        if (this.payload?.items) {
            const rValue = {};
            this.payload.items.forEach(item => {
                rValue[item.valueName] = {
                    "ui:widget":  item.items.uiType === "checkbox" ? "checkboxes" : "radio"
                }
            });

            return rValue;
        }
        return {}
    }

    get formData() {
        return {};
    }
}

export default PollFormAdapter;