import { connect } from 'react-redux';
import Notification from "../../component/plugin/Notification/Notification";

function mapStateToProp(state, ownProps) {
    const {payload} = ownProps;

    const parsed = JSON.parse(payload ?? "{}");
    const assetId = parsed?.assetId;

    return {
        assetId
    }
}

function mapDispatchToProps(dispatch){
}

export default connect(mapStateToProp, mapDispatchToProps)(Notification);

