import SocketVisitor from "../../model/visitor/SocketVisitor";
import {briefingJoinPendingSet, briefingPassCodeSet} from "../briefing";
import {MESSAGE_JOIN} from "../../constant";

export const fetchBriefingJoin = (passCode) => (dispatch, getState) => {
    const state = getState();
    const socketVisitor = new SocketVisitor(state.transportReducer);
    const socket = socketVisitor.getSocket();

    dispatch(briefingPassCodeSet(passCode));
    socket.emit(MESSAGE_JOIN, {passCode});
    dispatch(briefingJoinPendingSet());
}