import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Copyright from "./Copyright";
import useStyles from "./useStyles";
import geckonPaw from "../resources/img/lapka-01.svg"

function Layout(props) {
    const classes = useStyles();
    const  { footerCopyright } = props;

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    color="transparent"
                    position="relative">
                    <Toolbar>                        
                        <img style={{width: "40px", height: "40px"}} src={geckonPaw} alt="geckon paw icon" />                                                    
                        <Typography >
                            <div className="header-text">ZAP<br/>Interactive Presentation</div>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container component="main" maxWidth={props.ContainerMaxWidth ?? "sm"}>
                    <CssBaseline />
                    {props.children}
                    <CssBaseline />
                </Container>
                <Copyright className={footerCopyright ? classes.footer : null }/>
            </div>
    </>)
}

export default Layout;