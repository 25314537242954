import VisitorBase from "./VisitorBase";
const ns = "error"

class ErrorVisitor extends VisitorBase {
    constructor(state) {
        super(state, ns);
        if (!this.state[ns]) {
            this.state[ns] = {};
        }

        this.error =this.state[ns];
    }

    static fromProps(props) {
        return VisitorBase.fromProps(props, ns, (state) => new ErrorVisitor(state));
    }

    setError(e) {
        this.error.error = e;
    }

    resetError() {
        delete this.error.error;
    }
}

export default ErrorVisitor;