import React from "react";
import useStyles from "../../useStyles";
import config from "../../../config";
import Layout from "../../Layout";
import Paper from "@material-ui/core/Paper";

function Notification(props) {
    const classes = useStyles();

    const {assetId} = props;

    const src = config.briefingAssetUrl(assetId);
    return (<Layout footerCopyright={true} ContainerMaxWidth="sm">
            <div className={classes.canvas}>
                <Paper>
                    <img style={{maxWidth: "100vw"}} src={src}  alt="cta"/>
                </Paper>
            </div>
    </Layout>);
}

export default Notification;