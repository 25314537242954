import io from 'socket.io-client';
import {errorReset, errorSet} from "../error";
import {socketBroadcast, socketWelcome, socketRoomJoined, socketRoomNotFound, socketSet} from "../socket";
import config from "../../config";
import {briefingJoinPendingReset} from "../briefing";
import BriefingVisitor from "../../model/visitor/BriefingVisitor";
import {fetchWim} from "./fetchWim";

import {
    MESSAGE_BROADCAST, MESSAGE_HELLO,
    MESSAGE_ROOM_JOINED,
    MESSAGE_ROOM_NOT_FOUND,
    MESSAGE_WELCOME
} from "../../constant";


export const fetchConnection = () => (dispatch, getState) => {
    try {
        dispatch(errorReset());
        const socket = io(config.socketUrl());
        dispatch(socketSet(socket));

        socket.on(MESSAGE_WELCOME, (data) => {
            dispatch(socketWelcome(data));
        });
        socket.on(MESSAGE_ROOM_JOINED, (data) => {
            dispatch(socketRoomJoined(data));
            dispatch(briefingJoinPendingReset());
            dispatch(fetchWim(data));
        });
        socket.on(MESSAGE_ROOM_NOT_FOUND, (data) => {
            dispatch(socketRoomNotFound(data));
            dispatch(briefingJoinPendingReset());
        });
        socket.on(MESSAGE_BROADCAST, (data) => {
            dispatch(socketBroadcast(data));
        });

        socket.on('disconnect', () => {
            handleDisconnect(socket, dispatch)
        });

        socket.on('connect', () => {
            handleConnect(socket, dispatch, getState);
        })
    }
    catch(e) {
        dispatch(errorSet(e));
    }
}

function handleDisconnect(socket, dispatch) {
    setTimeout(() => socket.open(), 1000);
}

function handleConnect(socket, dispatch, getState) {
    const state = getState();
    const briefingVisitor = new BriefingVisitor(state.pluginReducer);
    const context = briefingVisitor.getContext();
    if (context) {
        const {clientId, briefingId} = context;
        socket.emit(MESSAGE_HELLO, {clientId, conferenceId: briefingId});
    }
    else {
        socket.emit(MESSAGE_HELLO);
    }
}