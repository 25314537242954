import React from "react";

function ButtonRadio(props) {
    const { style, checked, id, onChange, color } = props;
    console.log(style);

    const buttonStyle = checked
        ? {...style, border: `4px solid ${color}`}
        : {...style}

    return (<div
        id={id}
        style={buttonStyle}
        onClick={onChange}
    />);
}

export default ButtonRadio;