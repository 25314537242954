const apiUrl = () => {    
    return process.env.NODE_ENV === "development" ? "http://192.168.100.173:1221" : "//api.ebe.dev";
}

const socketUrl = () => {
    return process.env.NODE_ENV === "development" ? "http://192.168.100.173:1221" : "//api.ebe.dev";
}

const config = {
    apiUrl(path) {
        return `${apiUrl()}${path}`;
    },

    socketUrl() {
        return socketUrl();
    },

    briefingCssUrl(briefingId) {
        return `${apiUrl()}/api/v1/conference/css/${briefingId}`;
    },

    briefingAssetUrl(assetId) {
        return `${apiUrl()}/api/v1/asset/${assetId}`;
    }
};

export default config;
