import React from "react";
import Layout from "./Layout";
import Typography from "@material-ui/core/Typography";
import useStyles from "./useStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";

function randomInt(min, max) {
    return min + Math.floor((max - min) * Math.random());
}

function StatusView(props) {
    const { additionalMessage, message, spinner,
        footerCopyright, restartVisible,
        onRestart
    } = props;
    const classes = useStyles();

    let rollMessage = null;
    if (additionalMessage && additionalMessage.length > 0) {
        const messageIndex = randomInt(0, additionalMessage.length - 1);
        rollMessage = additionalMessage[messageIndex];
    }

    return (<Layout footerCopyright={footerCopyright}>
        <div className={classes.statusPaper}>
            <Typography component="h1" variant="h5">
                <span dangerouslySetInnerHTML={{__html: message}} />
            </Typography>
            {(spinner) ? (<CircularProgress className={classes.verticalCenter} />) : null }
            <div>&nbsp;</div>
            {(restartVisible) ? (<Button variant="contained" color="secondary" onClick={onRestart}>Reconnect</Button>) : null}
            {rollMessage != null ?
                (<Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div dangerouslySetInnerHTML={{__html: rollMessage}} />
                </Slide>) : (<div/>)}
        </div>
    </Layout>);
}

export default StatusView;