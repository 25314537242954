import React from 'react';
import CdfJsonForm from "../../container/plugin/Form/CdfJsonForm";
import JoinForm from "../../container/plugin/Form/JoinForm";
import Notification from "../../container/Notification/Notification";
import HeatmapAreas from "../../container/plugin/HeatmapAreas";
import HeatmapPoi from "../../container/plugin/HeatmapPoi";
import PollForm from "../../container/plugin/Form/PollForm";
import LayoutForm from "../../container/plugin/Form/LayoutForm";

class PluginFactory {
    constructor() {
        this.factory = {
            "AREAS-HEATMAP": (meta, payload, context) => (<HeatmapAreas context={context} payload={payload} meta={meta} />),
            "POI-HEATMAP": (meta, payload, context) => (<HeatmapPoi context={context} payload={payload} meta={meta} />),
			"CSIDE-FORM": (meta, payload, context) => (<CdfJsonForm context={context} payload={payload} meta={meta} />),
			"JOIN-FORM": (meta, payload, context) => (<JoinForm context={context} payload={payload} meta={meta} />),
            "POLL-FORM": (meta, payload, context) => (<PollForm context={context} payload={payload} meta={meta} />),
            "LAYOUT-FORM": (meta, payload, context) => (<LayoutForm context={context} payload={payload} meta={meta} />),
            "NOTIFICATION": (meta, payload, context) => (<Notification context={context} payload={payload} meta={meta} />)
        }
    }

    create(name) {
        const n = name.toUpperCase();
        if (this.factory[n])
            return this.factory[n];

        return null;
    }
}

export default PluginFactory;
