import {MESSAGE_BROADCAST, MESSAGE_HELLO, MESSAGE_ROOM_JOINED, MESSAGE_ROOM_NOT_FOUND, SOCKET_SET} from "../constant";

export const socketSet = (socket) => ({
    type: SOCKET_SET,
    socket
})

export const socketWelcome = (data) => ({
    type: MESSAGE_HELLO,
    data
});

export const socketRoomJoined = (data) => ({
    type: MESSAGE_ROOM_JOINED,
    data
});

export const socketRoomNotFound = (data) => ({
    type: MESSAGE_ROOM_NOT_FOUND,
    data
});

export const socketBroadcast = (data) => ({
    type: MESSAGE_BROADCAST,
    data
});