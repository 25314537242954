import {connect} from 'react-redux';
import LayoutForm from "../../../component/plugin/Form/LayoutForm";
import {uiValueChange} from "../../../action/ui";
import {fetchPluginDataSubmitCustom} from "../../../action/network/fetchPluginDataSubmitCustom";

function mapStateToProp(state, ownProps) {
    const {payload} = ownProps;

    const parsed = JSON.parse(payload ?? "{}");

    const controls = parsed?.controls;
    const assetId = parsed?.assetId;

    const formLayout = state.uiReducer["form-layout"] ?? {};
    const selected = {};
    for (let item in formLayout) {
        if (!formLayout.hasOwnProperty(item))
            continue;

        selected[formLayout[item]] = true;
    }

    return {
        controls, assetId, formLayout, selected
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onTextChanged: (formLayout, id) => (event) => {
            formLayout[id] = event.target.value;
            dispatch(uiValueChange("form-layout", formLayout))
        },
        onFormSubmit: (selected) => {
            dispatch(fetchPluginDataSubmitCustom("layout-form", selected))
        },
        onRadioChecked: (formLayout, group, id) => (event) => {
            formLayout[group] = id;
            dispatch(uiValueChange("form-layout", formLayout))
        },
        onCheckboxChecked: (formLayout, group, id) => (event) => {
            if (formLayout[group] === id) {
                delete formLayout[group]
            }
            else {
                formLayout[group] = id;
            }
            dispatch(uiValueChange("form-layout", formLayout))
        },
        onSliderChanged: (formLayout, id) => (evt, value) => {
            formLayout[id] = value;
            dispatch(uiValueChange("form-layout", formLayout))
        }
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(LayoutForm);