import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import "./style.scss";

import {applyMiddleware, compose, createStore} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk';
import {loadState, saveState} from './model/storage/sessionStorage';
import rootReducer from './reducer'

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunk)
);

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, enhancer);

store.subscribe(() => {
    saveState(store.getState());
});

ReactDOM.render((<Provider store={store}><App/></Provider>), document.getElementById('root'));
