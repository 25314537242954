import {connect} from 'react-redux';
import {fetchPluginDataSubmit} from "../../../action/network/fetchPluginDataSubmit";
import CdfJsonForm from "../../../component/plugin/Form/CdfJsonForm";
import {pluginDataUpdateMany} from "../../../action/plugin";
import {uiValueChange} from "../../../action/ui";

function mapStateToProp(state, ownProps) {
	return {
		cdfForm: state.uiReducer["web"]
	}
}

function mapDispatchToProps(dispatch) {
	return {
		onFormSubmit: () => dispatch(fetchPluginDataSubmit("web")),
		onFormDataChange: (formMeta) => ({formData}, e) => {
			const pairs = [];
			for (let key in formData) {
				if (!formData.hasOwnProperty(key))
					continue;

				const meta = formMeta.data.filter(m => m.id === key);
				if (!meta || meta.length !== 1)
					continue;
				const {friendlyName} = meta[0];
				pairs.push({
					key: friendlyName,
					value: formData[key]
				});
			}

			dispatch(pluginDataUpdateMany("web", pairs));
			dispatch(uiValueChange("web", formData));
		}
	};
}

export default connect(mapStateToProp, mapDispatchToProps)(CdfJsonForm);

