import {connect} from 'react-redux';
import ButtonRadio from "../../../../component/plugin/Form/Controls/ButtonRadio";

function mapStateToProp(state, ownProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProp, mapDispatchToProps)(ButtonRadio);

