import { connect } from 'react-redux';
import { pluginDataUpdate } from "../../action/plugin";
import { fetchPluginDataSubmit } from "../../action/network/fetchPluginDataSubmit";
import PluginVisitor from "../../model/visitor/PluginVisitor";
import HeatmapPoi from "../../component/plugin/Heatmap/HeatmapPoi";

function mapStateToProp(state, ownProps) {
    const {payload} = ownProps;
    const pluginVisitor = new PluginVisitor(state.pluginReducer)
    const data = pluginVisitor.getData("heatmap");

    const parsed = JSON.parse(payload ?? "{}");
    const dots = parsed?.colors;
    const message = parsed?.message;
    const assetId = parsed?.assetId;

    let stepIndex = 0;
    if (data && Object.keys(data).length > 0)
        stepIndex = 1;
    if (data && Object.keys(data).length === dots.length)
        stepIndex = 2;

    return {
        sendVisible: data ? Object.keys(data).length === dots.length : false,
        dots,
        stepIndex,
        message, assetId
    };
}

function mapDispatchToProps(dispatch){
    return {
        onPointSet: (pointNo, position) => dispatch(pluginDataUpdate("heatmap", `heatmap-point-${pointNo}`, position)),
        onPointsSubmit: () => dispatch(fetchPluginDataSubmit("heatmap")),
    };}

export default connect(mapStateToProp, mapDispatchToProps)(HeatmapPoi);

