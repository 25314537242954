import React from "react";
import POI from "./POI";
import Layout from "../../Layout";
import useStyles from "../../useStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Fab from "@material-ui/core/Fab";
import Zoom from '@material-ui/core/Zoom';
import SendIcon from '@material-ui/icons/Send';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import config from "../../../config";

function HeatmapAreas(props) {
    const steps = ["Drag & Drop Numbered Points", "Re-Arrange Points", "Submit"];

    const classes = useStyles();
    const { stepIndex, sendVisible, dots, onPointSet, onPointsSubmit, message, assetId } = props;
    const name=["low", "med", "top"]
    const src = config.briefingAssetUrl(assetId);
    return (
        <Layout footerCopyright={true} ContainerMaxWidth="xl">
            <Grid container>
                <Grid item xs>
                    <Paper className={classes.message}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Avatar>!</Avatar>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <span dangerouslySetInnerHTML={{__html: message}} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper className={classes.message}>
                    <Stepper
                        alternativeLabel
                             activeStep={stepIndex}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepButton completed={index < stepIndex}>
                                        {label}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    </Paper>
                </Grid>
            </Grid>
            <div className={classes.canvas}>
                <div className="heatmapContainer">
                    <Zoom in={sendVisible}>
                        <Fab color="primary" className={classes.fab} onClick={onPointsSubmit}><SendIcon /></Fab>
                    </Zoom>
                    <img src={src} id="img-canvas" className="img-area" alt="heatmap"/>
                    {
                        dots.map((x, index) => {
                            const c = x.split(',');

                            return (
                                <POI
                                     pos={{x: `${(dots.length - index - 1) * 50}`, y: `-60`}}
                                     className="dot"
                                     color={`rgba(${c[1]}, ${c[2]}, ${c[3]}, 0.4)`}
                                     onPositionUpdated={(pos) => onPointSet(index, pos)}>
                                    <span>{dots.length === 3 ? name[index] : dots.length - index}</span>
                                </POI>
                            )
                        })
                    }
                </div>
            </div>
        </Layout>)
}

export default HeatmapAreas;