import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

class POI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pos: this.props.pos ?? {x: 0, y: 0},
            dragging: false,
            rel: null // position relative to the cursor
        }

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        const element = $(ReactDOM.findDOMNode(this));
        element[0].addEventListener('mousedown',this.onMouseDown, { passive: false });
        element[0].addEventListener('touchstart', this.onTouchStart, { passive: false });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.dragging && !prevState.dragging) {
            document.addEventListener('mousemove', this.onMouseMove, { passive: false });
            document.addEventListener('mouseup', this.onMouseUp, { passive: false });
            document.addEventListener("touchmove", this.onTouchMove, { passive: false });
            document.addEventListener("touchend", this.onTouchEnd, { passive: false });
            document.addEventListener("scroll", this.onScroll, {passive: false});
        } else if (!this.state.dragging && prevState.dragging) {
            document.removeEventListener('mouseup', this.onMouseUp);
            document.removeEventListener('mousemove', this.onMouseMove);
            document.removeEventListener('touchmove', this.onTouchMove);
            document.removeEventListener('touchend', this.onTouchEnd);
            document.removeEventListener("scroll", this.onScroll);
        }
    }

    onScroll(evt) {
        if (this.state.dragging) {
            evt.stopPropagation();
            evt.preventDefault();
        }
    }

    onMouseDown(evt) {
        const element = $(ReactDOM.findDOMNode(this));
        this.containerOffset = $(".heatmapContainer").offset();

        // only left mouse button
        if (evt.button !== 0) return
        const pos = element.offset()
        this.setState({
            dragging: true,
            rel: {
                x: evt.pageX - pos.left,
                y: evt.pageY - pos.top
            }
        })
        evt.stopPropagation();
        evt.preventDefault();
    }

    onTouchStart(evt) {
        const element = $(ReactDOM.findDOMNode(this));
        const touchLocation = evt.targetTouches[0];
        this.containerOffset = $(".heatmapContainer").offset();

        const pos = element.offset()
        this.setState({
            dragging: true,
            rel: {
                x: touchLocation.pageX - pos.left,
                y: touchLocation.pageY - pos.top
            }
        })
        evt.stopPropagation();
        evt.preventDefault();
    }

    onTouchEnd(evt) {
        const { onPositionUpdated } = this.props;

        const img = $("#img-canvas");

        const realWidth = img[0].naturalWidth;
        const realHeight = img[0].naturalHeight;

        const kx = realWidth / img.width();
        const ky = realHeight/ img.height();

        this.setState({dragging: false})

        if (onPositionUpdated) {
            const pos = $(ReactDOM.findDOMNode(this)).offset();
            const size = {
                width: $(ReactDOM.findDOMNode(this)).width(),
                height: $(ReactDOM.findDOMNode(this)).height()
            };

            onPositionUpdated({
                x: (pos.left - this.containerOffset.left + size.width / 2) * kx,
                y: (pos.top - this.containerOffset.top + size.height / 2) * ky
            });
        }

        evt.stopPropagation();
        evt.preventDefault();
    }

    onMouseUp(evt) {
        const { onPositionUpdated } = this.props;

        const img = $("#img-canvas");

        const realWidth = img[0].naturalWidth;
        const realHeight = img[0].naturalHeight;

        const kx = realWidth / img.width();
        const ky = realHeight/ img.height();

        this.setState({dragging: false})

        if (onPositionUpdated) {
            const pos = $(ReactDOM.findDOMNode(this)).offset();
            const size = {
                width: $(ReactDOM.findDOMNode(this)).width(),
                height: $(ReactDOM.findDOMNode(this)).height()
            };

            onPositionUpdated({
                x: (pos.left - this.containerOffset.left + size.width / 2) * kx,
                y: (pos.top - this.containerOffset.top + size.height / 2) * ky
            });
        }

        evt.stopPropagation();
        evt.preventDefault();
    }

    onTouchMove(evt) {
        if (!this.state.dragging) return
        const touchLocation = evt.targetTouches[0];

        this.setState({
            pos: {
                x: touchLocation.pageX - this.state.rel.x - this.containerOffset.left,
                y: touchLocation.pageY - this.state.rel.y - this.containerOffset.top
            }
        })

        evt.stopPropagation();
        evt.preventDefault();
    }

    onMouseMove(evt) {
        if (!this.state.dragging) return
        this.setState({
            pos: {
                x: evt.pageX - this.state.rel.x - this.containerOffset.left,
                y: evt.pageY - this.state.rel.y - this.containerOffset.top
            }
        })
        evt.stopPropagation();
        evt.preventDefault();
    }

    render() {
        const {blendMode} = this.props;

        return (
            <div
                className={this.props.className} id={this.props.id}
                style={{
                    mixBlendMode: {blendMode},
                    position: "absolute",
                    left: `${this.state.pos.x}px`,
                    top: `${this.state.pos.y}px`,
                    backgroundColor: this.props.color,
                    height: "40px",
                    width: "40px",
                    touchAction: "none"
                }}
                >
                { this.props.children }
        </div>)
    }
}

export default POI;