import { connect } from 'react-redux';
import VirtualPluginView from "../component/VirtualPluginView";
import BriefingVisitor from "../model/visitor/BriefingVisitor";
import SocketVisitor from "../model/visitor/SocketVisitor";
import PluginVisitor from "../model/visitor/PluginVisitor";

import {fetchBriefingJoin} from "../action/network/fetchBriefingJoin";
import {fetchConnection} from "../action/network/fetchConnection";


function mapStateToProp(state, ownProps) {
    const briefingVisitor = new BriefingVisitor(state.pluginReducer);
    const pluginVisitor = new PluginVisitor(state.pluginReducer);
    const socketVisitor = new SocketVisitor(state.transportReducer);
    const briefingStarted = state.uiReducer.briefingStarted;


    return {
        context: {...briefingVisitor.getContext()},
        joinPending: briefingVisitor.isJoinPending(),
        ...pluginVisitor.toProps(),
        briefingStarted,
        hasConnection: !!socketVisitor.getSocket()
    };
}

function mapDispatchToProps(dispatch){
    return {
        onFetchConnection: () => dispatch(fetchConnection()),
        onJoin: (passCode) => dispatch(fetchBriefingJoin(passCode))
    };
}

export default connect(mapStateToProp, mapDispatchToProps)(VirtualPluginView);

