import VisitorBase from "./VisitorBase";
const ns = "briefing";

class BriefingVisitor extends VisitorBase{
    constructor(state) {
        super(state, ns);
        if (!this.state[ns])
            this.state[ns] = {};

        this.briefing = this.state[ns];
    }

    static fromProps(props) {
        return VisitorBase.fromProps(props, ns, (state) => new BriefingVisitor(state));
    }

    getContext() {
        return this.briefing.context;
    }

    setContext({clientId, briefingId, passCode}) {
        if (!this.briefing.context) {
            this.briefing.context = {};
        }

        if (clientId)
            this.briefing.context.clientId = clientId;
        if (briefingId)
            this.briefing.context.briefingId = briefingId;
        if (passCode)
            this.briefing.context.passCode = passCode;
    }

    setJoinPending() {
        this.briefing.joinPending = true;
    }

    resetJoinPending() {
        this.briefing.joinPending = false;
    }

    isJoinPending() {
        if (this.briefing.joinPending !== undefined )
            return this.briefing.joinPending;

        return false;
    }
}

export default BriefingVisitor;