import SocketVisitor from "../model/visitor/SocketVisitor";
import {MESSAGE_BROADCAST, SOCKET_SET} from "../constant";
import _ from "underscore";

const transportReducer = (state = {}, action) => {
    if (!state) {
        state = {}
    }

    switch (action.type) {
        case SOCKET_SET: {
            const {socket} = action;
            const socketVisitor = new SocketVisitor(state);
            socketVisitor.setSocket(socket);
            return socketVisitor.getState();
        }
        case MESSAGE_BROADCAST: {
            const {data} = action;
            data.data = _.isString(data.data) ? JSON.parse(data.data) : data.data;
            if (Array.isArray(data.data)) {
                data.data = data.data[0];
            }

            const {timestamp} = data.data;
            const socketVisitor = new SocketVisitor(state);
            socketVisitor.setLastTimeStamp(timestamp);
            return socketVisitor.getState();
        }

        default:
            return state;
    }
}

export default transportReducer;