import React from "react";
import POI from "./POI";
import Layout from "../../Layout";
import useStyles from "../../useStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Fab from "@material-ui/core/Fab";
import Zoom from '@material-ui/core/Zoom';
import SendIcon from '@material-ui/icons/Send';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import config from "../../../config";

function HeatmapPoi(props) {
    const steps = ["Drag & Drop Numbered Points", "Re-Arrange Points", "Submit"]

    const classes = useStyles();
    const { stepIndex, sendVisible, dots, onPointSet, onPointsSubmit, message, assetId } = props;

    const src = config.briefingAssetUrl(assetId);
    return (
        <Layout footerCopyright={true} ContainerMaxWidth="sl">
            <Grid container>
                <Grid item xs>
                    <Paper className={classes.message}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Avatar>!</Avatar>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <span dangerouslySetInnerHTML={{__html: message}} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs>
                    <Paper className={classes.message}>
                    <Stepper alternativeLabel
                             activeStep={stepIndex}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepButton completed={index < stepIndex}>
                                        {label}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    </Paper>
                </Grid>
            </Grid>
            <div className={classes.canvas}>
                <div className="heatmapContainer">
                    <Zoom in={sendVisible}>
                        <Fab color="primary" className={classes.fab} onClick={onPointsSubmit}><SendIcon /></Fab>
                    </Zoom>
                    <img src={src} id="img-canvas" className="img-area" alt="heatmap" />
                    {
                        dots.map((x, index) => {
                            const c = x.split(',');

                            return (
                                <POI pos={{x: `${index * 50 + 100}`, y: `-60`}}
                                     className="dot"
                                     color={`rgba(${c[0]}, ${c[1]}, ${c[2]}, 0.4)`}
                                     blendMode = "difference"
                                     onPositionUpdated={(pos) => {
                                         onPointSet(index, pos)
                                        }
                                     }>
                                    <span>{index + 1}</span>
                                </POI>
                            )
                        })
                    }
                </div>
            </div>
        </Layout>)
}

export default HeatmapPoi;