import React from "react";
import Layout from "../../Layout";
import useStyles from "../../useStyles";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import config from "../../../config";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import { Slider } from "@material-ui/core";
import ButtonRadio from "../../../container/plugin/Form/Control/ButtonRadio";

function LayoutForm(props) {
    const { controls, assetId, selected, formLayout,
        onFormSubmit, onRadioChecked, onCheckboxChecked,
        onTextChanged, onSliderChanged } = props;

    const classes = useStyles();
    const src = config.briefingAssetUrl(assetId);

    return (
        <Layout footerCopyright={true}>
            <div className={classes.canvas}>
                <div className="layoutFormControlsContainer">
                    <Zoom in={true}>
                        <Fab color="primary" className={classes.fab} onClick={() => onFormSubmit(formLayout)}><SendIcon/></Fab>
                    </Zoom>
                    <img src={src} className="img-area" alt="slide background"/>
                    {
                        controls.map(c => {
                            switch (c.controlType) {
                                case "text":{
                                    return (
                                        <textarea
                                            key={c.id}
                                            id={c.id}
                                            style={{
                                                padding: "10px",
                                                color: `${c.color}`,
                                                position: "absolute",
                                                top: `${c.top}%`,
                                                left: `${c.left}%`,
                                                width: `${c.width}%`,
                                                height: `${c.height}%`
                                            }}
                                            onChange={onTextChanged(formLayout, c.id)}
                                        />);
                                }
                                case "buttonRadio": {
                                    return (
                                        <ButtonRadio
                                            key={c.id}
                                            id={c.id}
                                            checked={selected[c.id] ?? false}
                                            name={c.group}
                                            value={c.id}
                                            color={`${c.color}`}
                                            style={{
                                                cursor: "pointer",
                                                position: "absolute",
                                                top: `${c.top}%`,
                                                left: `${c.left}%`,
                                                width: `${c.width}%`,
                                                height: `${c.height}%`
                                            }}
                                            onChange={onRadioChecked(formLayout, c.group, c.id)}
                                        />
                                    )
                                }
                                case "radio": {
                                    return (
                                        <Radio
                                            key={c.id}
                                            id={c.id}
                                            checked={selected[c.id] ?? false}
                                            name={c.group}
                                            value={c.id}
                                            style={{
                                                color: `${c.color}`,
                                                position: "absolute",
                                                top: `${c.top}%`,
                                                left: `${c.left}%`,
                                                width: `${c.width}%`,
                                                height: `${c.height}%`
                                            }}
                                            onChange={onRadioChecked(formLayout, c.group, c.id)}
                                        />
                                    )
                                }
                                case "slider": {
                                    const operands = c.operands ? c.operands : [1, 10];
                                    const from = parseFloat(operands[0].toString());
                                    const to = parseFloat(operands[1].toString());

                                    return (<Slider
                                        key={c.id}
                                        defaultValue={from}
                                        valueLabelDisplay="auto"
                                        min={from}
                                        max={to}
                                        onChange={onSliderChanged(formLayout, c.id)}
                                        style={{
                                            color: `${c.color}`,
                                            position: "absolute",
                                            top: `${c.top}%`,
                                            left: `${c.left}%`,
                                            width: `${c.width}%`,
                                            height: `${c.height}%`
                                        }} />);
                                }
                                case "checkbox": {
                                    return (
                                        <Checkbox
                                            key={c.id}
                                            onChange={onCheckboxChecked(formLayout, c.id, c.id)}
                                            checked={selected[c.id] ?? false}
                                            style={{
                                                color: `${c.color}`,
                                                position: "absolute",
                                                top: `${c.top}%`,
                                                left: `${c.left}%`,
                                                width: `${c.width}%`,
                                                height: `${c.height}%`
                                            }}
                                        />
                                    )
                                }
                                default: {
                                    return null;
                                }
                            }
                        })
                    }
                </div>
            </div>
        </Layout>
    );
}

export default LayoutForm;