import {connect} from 'react-redux';
import {fetchPluginDataSubmit} from "../../../action/network/fetchPluginDataSubmit";
import JoinForm from "../../../component/plugin/Form/JoinForm";
import {pluginDataUpdateMany} from "../../../action/plugin";
import {uiValueChange} from "../../../action/ui";

function mapStateToProp(state, ownProps) {
	return {
		joinForm: state.uiReducer["join-form"]
	};
}

function mapDispatchToProps(dispatch) {
	return {
		onFormSubmit: () => dispatch(fetchPluginDataSubmit("join-form")),
		onFormDataChange: ({formData}, e) => {
			const pairs = [];
			for (let key in formData) {
				if (!formData.hasOwnProperty(key))
					continue;

				pairs.push({
					key,
					value: formData[key]
				});
			}

			dispatch(pluginDataUpdateMany("join-form", pairs));
			dispatch(uiValueChange("join-form", formData));
		}
	};
}

export default connect(mapStateToProp, mapDispatchToProps)(JoinForm);

