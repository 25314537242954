import React, {useEffect} from "react";
import config from "../config";
import BriefingJoin from "../container/BriefingJoin";
import PluginVisitor from "../model/visitor/PluginVisitor";
import PluginFactory from "../model/plugin/PluginFactory";
import StatusView from "../container/StatusView";

function _addCss(fileName) {
    const head = document.head;
    const link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = fileName;

    head.appendChild(link);
}


function VirtualPluginView(props) {
    const { joinPending, context,
        onFetchConnection, briefingStarted,
        onJoin, hasConnection } = props;
    const pluginVisitor = PluginVisitor.fromProps(props);
    const cdfConfig = pluginVisitor.getConfig();

    console.log(cdfConfig);

    useEffect(() => {
        if (!context.clientId) {
            onFetchConnection();
        }
        else if (!hasConnection){
            onFetchConnection();
        }
        else if (context.briefingId) {
            _addCss(config.briefingCssUrl(context.briefingId))
        }
    }, [context.briefingId, context.clientId, onFetchConnection, hasConnection]);

    if (!context.clientId) {
        return (<StatusView spinner={true} message="Connecting..." />)
    }

    if (!context.passCode) {
        const passCode = props.match.params.id;
        if (passCode) {
            onJoin(passCode);
            return (<StatusView spinner={true} message="Connecting..." />)
        }

        return (<BriefingJoin />);
    }

    if (context.passCode && joinPending) {
        return (<StatusView spinner={true} message="Join pending. Please wait..." />);
    }

    if (!context.briefingId) {
        return (<StatusView restartVisible="true"
                            message="Cannot connect... <br/>The briefing passcode is not found or expired"/>)
    }

    const currentPlugin = pluginVisitor.getPlugin()

    if (currentPlugin) {
        const pluginFactory = new PluginFactory();
        const factory = pluginFactory.create(currentPlugin);
        if (factory)
            return factory(pluginVisitor.getMeta(), pluginVisitor.getPayload(), context);
    }

    const txtStarting = cdfConfig?.txtStarting ?? "Thanks for your input! Now hang tight till the next interactive segment.";
    const txtWaiting = cdfConfig?.txtWaiting ?? "Waiting for Interactive Briefing to start...";

    return (<StatusView footerCopyright={true} spinner={false}
                        message={ briefingStarted
                            ? txtStarting
                            : txtWaiting
                        }
                        additionalMessage = { cdfConfig?.txtMessages ?? []} />);
}

export default VirtualPluginView;
