import VisitorBase from "./VisitorBase";
const ns = "plugin";

class PluginVisitor extends VisitorBase {
    constructor(state) {
        super(state, ns);
        if (!this.state[ns])
            this.state[ns] = {};

        this.plugin = this.state[ns];
    }

    static fromProps(props) {
        return VisitorBase.fromProps(props, ns, (state) => new PluginVisitor(state));
    }

    getPlugin() {
        return this.plugin?.broadcast?.data?.plugin;
    }

    setBroadcast(payload) {
        this.plugin.broadcast = payload;
    }

    getPayload() {
        return this.plugin?.broadcast?.data?.payload;
    }

    getMeta() {
        return this.plugin?.broadcast?.data?.meta;
    }

    getUid() {
        return this.plugin?.broadcast?.data?.uid;
    }

    updateData({plugin, key, value}) {
        if (this.plugin) {
            if (!this.plugin[plugin])
                this.plugin[plugin] = {};

            this.plugin[plugin][key] = value;
        }
    }

    getData(plugin) {
        return this.plugin[plugin];
    }

    resetData(plugin) {
        if (this.plugin[plugin])
            delete this.plugin[plugin];
    }

    resetPlugin({plugin}) {
        if (this.plugin.broadcast)
            delete this.plugin.broadcast;

        this.resetData(plugin);
    }

    setSubmit(uid, timestamp) {
        const item = `${uid}--${timestamp}`;
        if (!this.plugin.experiences) {
            this.plugin.experiences = [item];
        }
        else {
            this.plugin.experiences.push(item);
        }
    }

    checkSubmitted(uid, timestamp) {
        if (!this.plugin?.experiences)
            return false;

        return this.plugin.experiences.includes(`${uid}--${timestamp}`);
    }

    getTimestamp() {
        return this.plugin?.broadcast.data.timestamp;
    }

    setConfig(config) {
        this.plugin.config = JSON.parse(config.payload);
    }

    getConfig() {
        return this.plugin?.config;
    }
}

export default PluginVisitor;