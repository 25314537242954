export const ERROR_SET = "ERROR_SET";
export const ERROR_RESET = "ERROR_RESET";

export const SOCKET_SET = "SOCKET_SET";

export const MESSAGE_HELLO = "HELLO";
export const MESSAGE_WELCOME = "WELCOME";
export const MESSAGE_JOIN = "JOIN";
export const MESSAGE_ROOM_JOINED = "ROOM-JOINED";
export const MESSAGE_ROOM_NOT_FOUND = "ROOM-NOT-FOUND";
export const MESSAGE_BROADCAST = "BROADCAST";
export const MESSAGE_PRT_NOTIFY = "PRT-NOTIFY";

export const MESSAGE_WHAT_I_MISSED = "WHAT_I_MISSED";

export const BRIEFING_PASSCODE_SET = "BRIEFING_PASSCODE_SET";
export const BRIEFING_JOIN_PENDING = "BRIEFING_JOIN_PENDING";
export const BRIEFING_RESTART = "BRIEFING_RESTART";

export const UI_VALUE_CHANGE = "UI_VALUE_CHANGE";

export const PLUGIN_DATA_UPDATE = "PLUGIN_DATA_UPDATE";
export const PLUGIN_DATA_UPDATE_MANY = "PLUGIN_DATA_UPDATE_MANY";
export const PLUGIN_DATA_SUBMIT_SUCCESS = "PLUGIN_DATA_SUBMIT_SUCCESS";
