import {BRIEFING_JOIN_PENDING, BRIEFING_PASSCODE_SET, BRIEFING_RESTART} from "../constant";

export const briefingPassCodeSet = (passCode) => ({
    type: BRIEFING_PASSCODE_SET,
    passCode
});

export const briefingJoinPendingSet = () => ({
    type: BRIEFING_JOIN_PENDING,
    pending: true
});

export const briefingJoinPendingReset = () => ({
    type: BRIEFING_JOIN_PENDING,
    pending: false
});

export const briefingRestart = () => ({
    type: BRIEFING_RESTART
});