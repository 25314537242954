import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    verticalCenter: {
        verticalAlign: "middle",
        marginTop: "40px"
    },
    statusPaper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "transparent"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        padding: theme.spacing(2)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    canvas: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: "50px"
    },
    fab: {
        position: 'absolute',
        bottom: "10%",
        right: "10%",
    },
    message: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        overflow: "hidden",
        minHeight: "100px"
    }
}));

export default useStyles;