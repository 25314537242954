import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import VirtualPluginView from "./container/VirtualPluginView";

function App() {

    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path="/briefing/:id" component={ VirtualPluginView } />
                    <Route path="/" component={ VirtualPluginView } />
                </Switch>
            </BrowserRouter>
        </>
    )
}

export default App;
