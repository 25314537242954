class JsonFormAdapter {
	constructor(cdfForm) {
		this.cdfForm = cdfForm;
	}

	get jsonSchema() {
		const rValue = {};
		rValue.type = "object";
		rValue.required = this.cdfForm.__meta?.required;
		rValue.properties = {};
		this.cdfForm.data.forEach(x => {
			let controlMeta = {};
			if ((this.cdfForm.__meta) && (this.cdfForm.__meta[x.id]))
				controlMeta = this.cdfForm.__meta[x.id];

			const properties = {
				type: controlMeta.type ?? "string",
				title: x.friendlyName ?? x.id,
				...(controlMeta.validation ?? {})
			}
			rValue.properties[x.id] = properties;
		})

		return rValue;
	}

	get uiSchema() {
		return {};
	}

	get formData() {
		return {};
	}
}

export default JsonFormAdapter;
