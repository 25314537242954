class VisitorBase {
    constructor(state, namespace) {
        this.state = {...state};
        this.ns = namespace;
    }

    getState() {
        return this.state;
    }

    toProps() {
        return {[this.ns]: this.state};
    }
    static fromProps(props, namespace, factory) {
        return factory(props[namespace])
    }
}


export default VisitorBase;