import {PLUGIN_DATA_SUBMIT_SUCCESS, PLUGIN_DATA_UPDATE, PLUGIN_DATA_UPDATE_MANY} from "../constant";

export const pluginDataUpdate = (plugin, key, value) => ({
    type: PLUGIN_DATA_UPDATE,
    plugin, key, value
});

export const pluginDataUpdateMany = (plugin, pairs) => ({
    type: PLUGIN_DATA_UPDATE_MANY,
    plugin, pairs
});

export const pluginDataSubmitSuccess = (plugin) => ({
    type: PLUGIN_DATA_SUBMIT_SUCCESS,
    plugin
});