import React from "react";
import _ from "underscore";
import JsonFormAdapter from "../../../model/adapter/JsonFormAdapter";
import Form from "@rjsf/material-ui";
import Layout from "../../Layout";
import useStyles from "../../useStyles";

function CdfJsonForm(props) {
	const transformErrors = (errors, fieldsMeta) => {
		return errors.map(e => {
			const field = e.property.substring(1); // omit the first .
			const customError = (fieldsMeta[field]?.errors) ? fieldsMeta[field]?.errors[e.name] : null;
			e.message = customError ?? e.message;
			return e;
		});
	}

	const classes = useStyles();

	const {payload, onFormSubmit, onFormDataChange, cdfForm} = props;
	const {formMeta} = _.isString(payload) ? JSON.parse(payload) : payload;

	const jsonFormAdapter = new JsonFormAdapter(formMeta);
	const onError = (errors) => console.log(errors);

	return (
		<Layout footerCopyright={true}>
			<div className={classes.paper}>
				<Form schema={jsonFormAdapter.jsonSchema}
					  uiSchema={jsonFormAdapter.uiSchema}
					  onSubmit={onFormSubmit}
					  onChange={onFormDataChange(formMeta)}
					  formData={cdfForm ?? jsonFormAdapter.formData}
					  onError={onError}
					  showErrorList={false}
					  transformErrors={(errors) => transformErrors(errors, formMeta?.__meta)}
				/>
			</div>
		</Layout>
	);
};

export default CdfJsonForm;
