import {
    BRIEFING_JOIN_PENDING,
    BRIEFING_PASSCODE_SET, BRIEFING_RESTART,
    ERROR_RESET,
    ERROR_SET, MESSAGE_BROADCAST,
    MESSAGE_HELLO,
    MESSAGE_ROOM_JOINED,
    MESSAGE_ROOM_NOT_FOUND, PLUGIN_DATA_SUBMIT_SUCCESS, PLUGIN_DATA_UPDATE,
    PLUGIN_DATA_UPDATE_MANY,
} from "../constant";
import ErrorVisitor from "../model/visitor/ErrorVisitor";
import BriefingVisitor from "../model/visitor/BriefingVisitor";
import PluginVisitor from "../model/visitor/PluginVisitor";

import _ from "underscore";

const pluginReducer = (state = {}, action) => {
    if (!state) {
        state = {}
    }

    switch (action.type) {
        case BRIEFING_RESTART: {
            state = {}
            return state;
        }
        case ERROR_SET: {
            const {e} = action;
            const errorVisitor = new ErrorVisitor(state)
            errorVisitor.setError(e)
            return errorVisitor.getState();
        }
        case ERROR_RESET: {
            const errorVisitor = new ErrorVisitor(state)
            errorVisitor.resetError();
            return errorVisitor.getState();
        }
        case BRIEFING_PASSCODE_SET: {
            const {passCode} = action;
            const briefingVisitor = new BriefingVisitor(state);
            briefingVisitor.setContext({passCode});
            return briefingVisitor.getState();
        }
        case BRIEFING_JOIN_PENDING: {
            const {pending} = action;
            const briefingVisitor = new BriefingVisitor(state);
            pending ? briefingVisitor.setJoinPending() : briefingVisitor.resetJoinPending();
            return briefingVisitor.getState();
        }
        case MESSAGE_HELLO: {
            const {data} = action;
            const {id} = data;
            const briefingVisitor = new BriefingVisitor(state);
            briefingVisitor.setContext({clientId : id});
            return briefingVisitor.getState();
        }
        case MESSAGE_ROOM_JOINED: {
            const {data} = action;
            const {conferenceId} = data;
            const briefingVisitor = new BriefingVisitor(state);
            briefingVisitor.setContext({briefingId: conferenceId});
            return briefingVisitor.getState();
        }
        case MESSAGE_ROOM_NOT_FOUND: {
            const errorVisitor = new ErrorVisitor(state);
            errorVisitor.setError("Room not found");
            return errorVisitor.getState();
        }
        case MESSAGE_BROADCAST: {
            console.log(action);

            const {data} = action;

            data.data = _.isString(data.data) ? JSON.parse(data.data) : data.data;
            const pluginVisitor = new PluginVisitor(state);
            let config = null;

            if (Array.isArray(data.data)) {
                const configFilter = data.data.filter(x => x.plugin.toLowerCase() === "config");
                if (configFilter.length > 0) {
                    config = configFilter[0];
                }

                const notSubmitted = data.data.filter(x => !pluginVisitor.checkSubmitted(x.uid, x.timestamp) && x.plugin.toLowerCase() !== "config");
                if (notSubmitted.length > 0)
                    data.data = notSubmitted[0];
                else {
                    if (pluginVisitor.checkSubmitted(data.data[0].uid, data.data[0].timestamp))
                    {
                        // everything is submitted
                        return state;
                    }
                    else {
                        data.data = data.data[0];
                    }
                }
            }
            else {
                if (data.data.plugin.toLowerCase() === "config") {
                    config = data.data;
                }
            }

            if (config) {
               pluginVisitor.setConfig(config);
            }

            if (data.data !== config) {
                const {uid} = data.data;
                const {timestamp} = data;
                if (pluginVisitor.checkSubmitted(uid, timestamp))
                    return state; // ignore submitted!

                pluginVisitor.setBroadcast(data);

                const plugin = pluginVisitor.getPlugin();
                pluginVisitor.resetData({plugin});
            }

            return pluginVisitor.getState();
        }
        case PLUGIN_DATA_UPDATE: {
            const pluginVisitor = new PluginVisitor(state);
            pluginVisitor.updateData(action);
            return pluginVisitor.getState();
        }
        case PLUGIN_DATA_UPDATE_MANY: {
            const {pairs, plugin} = action;
            const pluginVisitor = new PluginVisitor(state);
            pairs.forEach(p => {
                pluginVisitor.updateData({plugin, ...p});
            });
            return pluginVisitor.getState();
        }
        case PLUGIN_DATA_SUBMIT_SUCCESS: {
            const pluginVisitor = new PluginVisitor(state);
            const uid = pluginVisitor.getUid();
            const timestamp = pluginVisitor.getTimestamp();
            pluginVisitor.setSubmit(uid, timestamp);
            pluginVisitor.resetPlugin(action);
            return pluginVisitor.getState();
        }

        default:
            return state;
    }
}

export default pluginReducer;