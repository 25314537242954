import {pluginDataSubmitSuccess} from "../plugin";
import PluginVisitor from "../../model/visitor/PluginVisitor";
import SocketVisitor from "../../model/visitor/SocketVisitor";
import BriefingVisitor from "../../model/visitor/BriefingVisitor";
import {MESSAGE_PRT_NOTIFY} from "../../constant";
import {fetchWim} from "./fetchWim";

export const fetchPluginDataSubmit = (plugin) => (dispatch, getState) => {
    try {
        const state = getState();
        const pluginVisitor = new PluginVisitor(state.pluginReducer);
        const socketVisitor = new SocketVisitor(state.transportReducer);
        const briefingVisitor = new BriefingVisitor(state.pluginReducer);

        const data = pluginVisitor.getData(plugin);
        const uid = pluginVisitor.getUid();

        const socket = socketVisitor.getSocket();
        const { briefingId, clientId } = briefingVisitor.getContext();

        socket.emit(MESSAGE_PRT_NOTIFY, {uid, plugin, clientId, conferenceId: briefingId, data});

        dispatch(pluginDataSubmitSuccess(plugin));
        dispatch(fetchWim({
            conferenceTimestamp: socketVisitor.getLastTimestamp()
        }));
    }
    catch (e) {
        console.log(e);
    }
}