import {MESSAGE_BROADCAST, PLUGIN_DATA_SUBMIT_SUCCESS, UI_VALUE_CHANGE} from "../constant";
import PluginVisitor from "../model/visitor/PluginVisitor";

const uiReducer = (state = {}, action) => {
    switch (action.type) {
        case PLUGIN_DATA_SUBMIT_SUCCESS: {
            const {plugin} = action;
            const newState = {
                ...state,
            };
            if (state[plugin]) {
                delete newState[plugin];
            }
            if (plugin.toLocaleUpperCase() !== "JOIN-FORM") {
                newState.briefingStarted = true;
            }
            return newState;
        }
        case UI_VALUE_CHANGE:{
            const {key, value} = action;
            return {
                ...state,
                [key]: value,
            }
        }
        case MESSAGE_BROADCAST: {
            // when new message received - reset all plugin data in UI
            const {data} = action;
            const pluginVisitor = new PluginVisitor({});
            pluginVisitor.setBroadcast(data);
            const plugin = pluginVisitor.getPlugin();

            if (state[plugin]) {
                const newState = {...state};
                delete newState[plugin];
                return newState;
            }

            return state;
        }
        default:
            return state;
    }
}

export default uiReducer;