import React from "react";
import Form from "@rjsf/material-ui";

import JoinFormAdapter from "../../../model/adapter/JoinFormAdapter";
import Layout from "../../Layout";
import useStyles from "../../useStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import _ from "underscore";

function JoinForm(props) {
	const classes = useStyles();
	const {onFormSubmit, onFormDataChange, joinForm, payload} = props;

	const packet = _.isString(payload) ? JSON.parse(payload) : payload;
	const joinFormAdapter = new JoinFormAdapter(packet.items);

	return (
		<Layout footerCopyright={true}>
			<Grid container>
				<Grid item xs>
					<Paper className={classes.message}>
						<Grid container wrap="nowrap" spacing={2}>
							<Grid item >
								<Avatar>!</Avatar>
							</Grid>
							<Grid item xs zeroMinWidth>
								<span dangerouslySetInnerHTML={{__html: packet.message}} />
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Paper className={classes.form}>
				<Form schema={joinFormAdapter.jsonSchema}
					  uiSchema={joinFormAdapter.uiSchema}
					  onChange={onFormDataChange}
					  onSubmit={onFormSubmit}
					  formData={joinForm}
					  showErrorList={false}
				/>
			</Paper>
		</Layout>
	);
}

export default JoinForm;
