import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import useStyles from "./useStyles";
import Layout from "./Layout";
import geckonPaw from "../resources/img/lapka-01.svg"

function BriefingJoin(props) {
    const {passCode, onPassCodeChange, onJoin} = props;
    const classes = useStyles();    
    return (
        <Layout ContainerMaxWidth="sm">
            <div className={classes.paper}>
               <Avatar className={classes.avatar}>
                    <img style={{width: "40px", height: "40px"}} src={geckonPaw} alt="geckon paw icon" />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Join Interactive Briefing
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="passcode"
                        label="Briefing Passcode"
                        name="passcode"
                        autoFocus
                        onChange={onPassCodeChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => onJoin(passCode)}
                    >
                        Join
                    </Button>
                </form>
            </div>
        </Layout>
    );
}

export default BriefingJoin;