import React from 'react'
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function Copyright(props) {
    const { className } = props;

    return (
        <Box className={className}>
            <Typography variant="body2" color="textSecondary" align="center">
                Copyright EBE.DEV. All Rights Reserved.                
            </Typography>
        </Box>
    )
}

export default Copyright;