import { connect } from 'react-redux';
import BriefingJoin from "../component/BriefingJoin";
import {uiValueChange} from "../action/ui";
import {fetchBriefingJoin} from "../action/network/fetchBriefingJoin";

function mapStateToProp(state, ownProps) {
    const { passCode } = state.uiReducer;

    return {
        passCode,
    };
}

function mapDispatchToProps(dispatch){
    return {
        onPassCodeChange: (evt) => dispatch(uiValueChange("passCode", evt.target.value)),
        onJoin: (passCode) => dispatch(fetchBriefingJoin(passCode))
    };
}

export default connect(mapStateToProp, mapDispatchToProps)(BriefingJoin);

